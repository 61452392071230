<template>
  <div>
    <header class="header">
      <v-container fluid>
        <v-row class="ml-10 mr-10">
          <v-col
            cols="12"
            sm="6"
            class="d-flex align-center justify-center justify-sm-start"
          >
            <v-img
              alt="Quiz Addict - Reper"
              :src="'/assets/logos/' + $i18n.locale + '/general.png'"
              max-width="160"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="d-flex align-center justify-center justify-sm-end"
          >
            <v-btn
              color="#C1C1C1"
              elevation="0"
              class="mr-sm-10 pt-4 pb-4 pl-6 pr-6 white--text"
              to="/"
              >{{ $t('show-quizes') }}</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </header>
    <v-main style="min-height: 700px">
      <v-img
        :src="
          mycategory != '' && step >= 2
            ? '/assets/backgrounds/background-groupe/background-groupe-' +
              mycategory +
              '.jpg'
            : '/assets/backgrounds/background-groupe/background-groupe-alcool.jpg'
        "
        min-height="100vh"
      >
        <v-container class="text-center">
          <h1 class="mb-8 mt-12 white--text">
            {{ $t('quiz-group-title') }}
          </h1>
        </v-container>
        <v-container v-if="step === 1">
          <v-card class="ma-4">
            <v-container>
              <v-row class="justify-center">
                <v-col>
                  <v-card-text class="ma-0 mt-4 mb-4" style="font-size: unset">
                    <h3 class="mt-0 ml-10 mb-5">
                      {{ $t('choose-mode') }}
                    </h3>
                    <template v-for="category in categories">
                      <v-card
                        v-if="category.quizes.length > 0"
                        class="ml-10 mb-4"
                        :key="category.id"
                        elevation="0"
                      >
                        <v-card-title
                          v-html="category.category"
                          class="title"
                        />
                        <v-card-text>
                          <v-chip
                            :color="myquiz == quiz ? colors[category.key] : ''"
                            :text-color="myquiz == quiz ? 'white' : ''"
                            v-for="quiz in category.quizes"
                            v-html="quiz.name"
                            class="mr-2 mb-2"
                            :key="quiz.id"
                            @click="
                              myquiz = quiz
                              color = quiz.main_color
                              mycategory = category.key
                            "
                          />
                        </v-card-text>
                      </v-card>
                    </template>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-container>
            <v-row>
              <v-col class="d-flex justify-end">
                <v-btn
                  @click="setStep(2)"
                  color="primary"
                  class="ma-8 pt-4 pb-4 pl-6 pr-6 text-capitalize next-btn"
                  :disabled="myquiz == {}"
                >
                  {{ $t('next') }}
                  <v-icon class="ml-2">mdi-arrow-right-thick</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
        <v-container v-if="step >= 2">
          <v-card class="ma-4">
            <v-container>
              <v-row>
                <v-col>
                  <v-card-text class="ma-0 mt-4 mb-4" style="font-size: unset">
                    <h3 class="mt-0 ml-10 mb-5">
                      {{ $t('info-quiz') }}
                    </h3>
                    <v-flex xs12 sm6 md6>
                      <p class="mt-4 ml-10">
                        {{ $t('mode') }}
                      </p>
                      <v-radio-group v-model="type" class="ml-10">
                        <v-radio
                          key="2"
                          :label="$t('mode-c')"
                          value="challenge"
                        ></v-radio>
                        <v-radio
                          key="1"
                          :label="$t('mode-e')"
                          value="teaching"
                        ></v-radio>
                      </v-radio-group>
                      <p class="mt-4 ml-10">
                        {{ $t('question-time') }}
                      </p>
                      <v-select
                        class="ml-10"
                        outlined
                        v-model="time"
                        :items="times"
                        :label="$t('nb-sec')"
                      ></v-select>
                    </v-flex>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-container>
            <v-row>
              <v-col cols="6">
                <v-btn
                  v-if="step > 1"
                  @click="setStep(1)"
                  class="ma-sm-8 mt-8 ml-2 pt-4 pb-4 pl-6 pr-6 text-capitalize next-btn primary--text"
                >
                  <v-icon class="ml-2">mdi-arrow-left-thick</v-icon>
                  {{ $t('previous') }}
                </v-btn>
                <v-btn to="/salon/home" v-else class="ma-8">
                  {{ $t('previous') }}
                </v-btn>
              </v-col>
              <v-col cols="6" class="d-flex justify-end">
                <v-btn
                  @click="setStep(3)"
                  color="primary"
                  class="ma-sm-8 mt-8 mr-2 pt-4 pb-4 pl-6 pr-6 text-capitalize next-btn"
                >
                  {{ $t('next') }}

                  <v-icon class="ml-2">mdi-arrow-right-thick</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <div class="d-flex justify-begin align-center mt-8">
            <h3 class="ma-6 headline"></h3>
          </div>
        </v-container>
      </v-img>
    </v-main>
  </div>
</template>

<script>
import apiCall from '@/utils/api.js'
import { db } from '../firebase'

export default {
  name: 'SalonSelect',
  data: () => ({
    name: '',
    times: [15, 20, 25, 30, 40, 60],
    questions: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    nb: 5,
    time: 30,
    code: '',
    type: 'challenge',
    step: 1,
    categories: [],
    cat: null,
    myquiz: {},
    mycategory: '',
    color: '',
    alea: 0,
    loading: true,
    background: '',
    colors: {
      sport: '#6d4371',
      hasard: '#82b623',
      ecrans: '#004c76',
      multi: '#f2bb3f',
      cannabis: '#52821d',
      alcool: '#820f01',
      general: '#f2bb3f',
    },
  }),
  created() {
    this.background = '/assets/backgrounds/background-quizaddict-large.jpg'
    apiCall({ url: '/', method: 'get' }).then((resp) => {
      this.categories = resp.data
      this.loading = false
    })
  },
  methods: {
    setStep(step) {
      if (step == 2 && !this.myquiz.key) {
        return
      }
      this.step = step
      if (step === 3) {
        let code = ''
        if (this.code === '') {
          code = Math.floor(Math.random() * 10000000).toString()
        } else {
          code = this.code
        }

        apiCall({
          url: '/' + this.myquiz.key,
          method: 'get',
        }).then((resp) => {
          const thos = this
          db.collection('salons')
            .doc(code)
            .set({
              type: this.type,
              time: this.time,
              code: code,
              quizkey: resp.data.key,
              category: this.mycategory,
              color: this.color,
              step: 0,
            })
            .then(function () {
              for (let i = 0; i < resp.data.questions.length; i++) {
                db.collection('salons/' + code + '/questions')
                  .doc(resp.data.questions[i].id)
                  .set({
                    question: resp.data.questions[i],
                    position: i,
                    correct: 0,
                    false: 0,
                  })
              }
              thos.$router.push('/salon-waiting/' + code)
            })
        })
      }
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$i18n.locale === 'de') {
          document.title = 'Bischfit | by REPER'
        } else {
          document.title = 'Quizaddict | by REPER'
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  -webkit-box-shadow: 0px 15px 23px -10px rgba(0, 0, 0, 0.36) !important;
  box-shadow: 0px 15px 23px -10px rgba(0, 0, 0, 0.36) !important;
}
.v-expansion-panels {
  .v-expansion-panel {
    border-radius: 7px;
    padding: 10px;
    margin: 10px 0;
  }
  margin-bottom: 150px !important;
}
.hasard a {
  color: #82b623 !important;
}
.footer-ecrans a {
  color: #004c76 !important;
}
.footer-multi a {
  color: #f2bb3f !important;
}
.footer-cannabis a {
  color: #52821d !important;
}
.footer-alcool a {
  color: #820f01 !important;
}
.footer-general a {
  color: #f2bb3f !important;
}

.btn-hasard,
.footer-hasard {
  background: #82b623 !important;
  color: #ffffff !important;
}
.btn-ecrans,
.footer-ecrans {
  background: #004c76 !important;
  color: #ffffff !important;
}
.btn-multi,
.footer-multi {
  background: #f2bb3f !important;
  color: #ffffff !important;
}

.btn-cannabis,
.footer-cannabis {
  background: #52821d !important;
  color: #ffffff !important;
}
.btn-alcool,
.footer-alcool {
  background: #820f01 !important;
  color: #ffffff !important;
}
.btn-general,
.footer-general {
  background: #f2bb3f !important;
  color: #ffffff !important;
}
.text-h3,
.text-h4,
.text-h5 {
  font-weight: bold !important;
}
.text-h7 {
  font-size: 30px;
}

.v-card__title,
.v-card__text {
  padding: 0;
}

.title {
  font-weight: bolder;
}

body {
}

.next-btn {
  font-family: 'Fredoka One', cursive;
  font-size: 20px !important;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: auto;
  color: white;
}
</style>
