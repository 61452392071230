import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './firebase'
import VueYouTubeEmbed from 'vue-youtube-embed'
import vuetify from './plugins/vuetify'
import { firestorePlugin } from 'vuefire'
import i18n from './i18n'
import titleMixin from './mixins/title-mixin'
import VueAnalytics from 'vue-analytics'

Vue.use(VueYouTubeEmbed, { global: true, componentId: 'youtube-media' })
Vue.mixin(titleMixin)

Vue.config.productionTip = false
Vue.use(firestorePlugin)
Vue.use(VueAnalytics, {
  id: 'UA-198048219-1',
  router,
})

new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
