var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"header"},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-start",attrs:{"cols":"6"}},[_c('v-img',{attrs:{"alt":"Quiz Addict - Reper","src":'/assets/logos/' + _vm.$i18n.locale + '/general.png',"max-width":"160"}})],1),_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"cols":"6"}},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{attrs:{"alt":"Logo Reper - Promotion de la santé","src":"/assets/logos/reper.svg","width":"150px","height":"80px","contain":""}})],1)],1)],1)],1)],1),_c('span',{staticClass:"bg"}),_c('v-main',{staticClass:"backgroundImg",staticStyle:{"min-height":"100%"}},[_c('v-img',{attrs:{"src":'/assets/backgrounds/background-groupe/background-groupe-' +
        _vm.salon.category +
        '.jpg',"min-height":"100%"}},[_c('v-container',{staticClass:"d-flex align-center justify-center",staticStyle:{"height":"100%"}},[_c('v-card',{staticClass:"ma-12 mx-6 d-flex pa-12 justify-center align-center flex-wrap",staticStyle:{"width":"95%","min-height":"calc(100vh - 300px)","position":"relative"}},[(_vm.salon.step === 'question' && _vm.step !== 'waitingresults')?_c('div',[(_vm.salon.workingquestion.type == 'singleChoice')?_c('v-radio-group',{model:{value:(_vm.answer),callback:function ($$v) {_vm.answer=$$v},expression:"answer"}},_vm._l((_vm.salon.workingquestion.answers),function(answer){return _c('v-radio',{key:answer.id,staticClass:"ma-3",attrs:{"label":answer.answer,"value":answer.id}})}),1):(_vm.salon.workingquestion.type == 'multipleChoice')?_c('div',_vm._l((_vm.salon.workingquestion.answers),function(an){return _c('v-checkbox',{key:an.id,staticClass:"ma-0 pa-0",staticStyle:{"margin":"10px 0"},attrs:{"label":an.answer,"value":an.id},model:{value:(_vm.answers),callback:function ($$v) {_vm.answers=$$v},expression:"answers"}})}),1):_vm._e(),_c('v-btn',{staticClass:"ma-8 pt-4 pb-4 pl-6 pr-6 text-capitalize next-btn",staticStyle:{"position":"absolute","bottom":"-10px","right":"0"},attrs:{"color":"primary","dark":""},on:{"click":_vm.check}},[_vm._v("Répondre")])],1):(_vm.step != 'waiting' && _vm.salon.step != 'finish')?_c('div',[(_vm.correct)?_c('v-img',{attrs:{"src":"/pictogrammes_juste.svg","max-height":"120px","contain":""}}):_vm._e(),(!_vm.correct)?_c('v-img',{attrs:{"src":"/pictogrammes_faux.svg","max-height":"120px","contain":""}}):_vm._e(),_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t('waiting-responses'))+" ")])],1):_vm._e(),(
              _vm.step === 'waiting' &&
              _vm.salon.step !== 'results' &&
              _vm.salon.step !== 'finish' &&
              _vm.salon.step !== 'question'
            )?_c('div',{staticClass:"d-flex align-center justify-center flex-wrap blink_text"},[_c('h1',{staticStyle:{"color":"#ddd","font-size":"50px","width":"100%","text-align":"center","margin-bottom":"20px"}},[_vm._v(" En attente ")]),_c('v-icon',{staticStyle:{"font-size":"60px"},attrs:{"color":"grey"}},[_vm._v("mdi-timer-sand")])],1):_vm._e(),(_vm.salon.step === 'finish')?_c('div',{staticClass:"d-flex align-center justify-center flex-wrap"},[_c('h1',{staticStyle:{"color":"#ddd","font-size":"50px","width":"100%","text-align":"center","margin-bottom":"20px"}},[_vm._v(" Merci de votre"),_c('br'),_vm._v("participation ")]),_c('v-icon',{staticStyle:{"font-size":"60px"},attrs:{"color":"grey"}},[_vm._v("mdi-emoticon-excited")])],1):_vm._e()])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }