<template>
  <div class="home overflow-hidden">
    <header class="header">
      <v-container fluid>
        <v-row class="ml-sm-10 mr-sm-10">
          <v-col cols="6">
            <router-link
              to="/"
              style="height: 100%; width: 100%"
              class="d-flex align-center justify-start"
            >
              <v-img
                alt="Quiz Addict - Reper"
                :src="'/assets/logos/' + $i18n.locale + '/general.svg'"
                max-width="140"
              >
              </v-img>
            </router-link>
          </v-col>
          <v-col cols="6">
            <a
              href="https://www.reper-fr.ch"
              target="_blank"
              style="height: 100%; width: 100%"
              class="d-flex align-center justify-end"
            >
              <v-img
                alt="Logo Reper - Promotion de la santé"
                src="/assets/logos/reper.svg"
                position="right"
                contain
                max-width="190"
              >
              </v-img>
            </a>
          </v-col>
        </v-row>
      </v-container>
    </header>
    <span class="bg"></span>
    <v-main class="backgroundImg" style="min-height: calc(100vh - 500px)">
      <v-img
        src="/assets/backgrounds/background-quizaddict-large.jpg"
        height="calc(100vh - 102px)"
      >
        <v-container
          class="d-flex align-center justify-center"
          style="height: 100%"
        >
          <v-card
            class="mx-6 d-flex px-12 justify-center align-center flex-wrap"
            style="min-height: calc(100vh - 500px); position: relative"
          >
            <div
              class="ma-12 px-7 d-flex flex-column justify-center align-center white--text"
            >
              <v-text-field
                v-if="!codeSet"
                v-model="mycode"
                @keyup.enter="go"
                value=""
                label="code pin"
                outlined
                style="font-weight: bold; color: #999; width: 200px"
              ></v-text-field>
              <v-text-field
                v-if="codeSet"
                v-model="pseudo"
                :rules="pseudoRules"
                @keyup.enter="go"
                value=""
                label="ton pseudo"
                outlined
                style="font-weight: bold; color: #999; width: 200px"
              ></v-text-field>
              <v-btn
                class="mt-2 mr-2 ma-md-10 pt-4 pb-4 pl-6 pr-6 text-capitalize next-btn"
                color="primary"
                @click="go"
                dark
                x-large
                style="width: 200px"
              >
                Entrer
                <v-icon class="ml-2">mdi-arrow-right-thick</v-icon>
              </v-btn>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-main>
  </div>
</template>

<script>
import { db } from '../firebase'

export default {
  name: 'Home',
  props: ['code'],
  data: () => ({
    codeSet: false,
    salon: {},
    pseudo: '',
    pseudoRules: [
      (v) => !!v || 'Pseudo obligatoire',
      (v) =>
        (v && v.length <= 10) || 'Ton pseudo doit faire moins de 10 caractères',
    ],
    mycode: '',
  }),
  created() {
    if (this.code != 'start') {
      let docRef = db.collection('/salons').doc(this.code)
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.salon = doc.data()
            this.codeSet = true
            this.mycode = this.salon.code
          } else {
            console.log('No such document!')
          }
        })
        .catch((error) => {
          console.log('Error getting document:', error)
        })
    }

    this.$vuetify.theme.themes.light.primary = '#b20028'
    this.$vuetify.theme.themes.light.secondary = '#b20028'
  },
  methods: {
    go() {
      let thos = this
      if (this.codeSet && this.pseudo && this.pseudo.length <= 10) {
        db.collection('salons/' + thos.mycode.replace(/\s/g, '') + '/players')
          .add({
            name: thos.pseudo,
            score: 0,
          })
          .then(function (doc) {
            thos.$router.push({
              path:
                '/playroom/' + thos.mycode.replace(/\s/g, '') + '/' + doc.id,
            })
          })
      } else if (thos.mycode) {
        let docRef = db
          .collection('/salons')
          .doc(thos.mycode.replace(/\s/g, ''))
        docRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              thos.salon = doc.data()
              thos.codeSet = true
            } else {
              console.log('No such document!')
            }
          })
          .catch((error) => {
            console.log('Error getting document:', error)
          })
      }
    },
  },
  components: {},
}
</script>

<style>
.backgroundImg-home {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
}

.body {
  font-size: 22px;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: auto;
  color: white;
}

.next-btn {
  font-family: 'Fredoka One', cursive;
  font-size: 20px !important;
}
</style>
