<template>
  <div :class="quiz.class">
    <header class="header">
      <v-container fluid>
        <v-row class="ml-10 mr-10">
          <v-col cols="12" sm="6">
            <router-link
              to="/"
              style="height: 100%; width: 100%"
              class="d-flex align-center justify-center justify-sm-start"
            >
              <v-img
                alt="Quiz Addict - Reper"
                :src="
                  '/assets/logos/' + $i18n.locale + '/' + quiz.class + '.png'
                "
                max-width="140"
              >
              </v-img>
            </router-link>
          </v-col>
          <v-col cols="12" sm="6">
            <a
              href="https://www.reper-fr.ch"
              target="_blank"
              style="height: 100%; width: 100%"
              class="d-flex align-center justify-center justify-sm-end"
            >
              <v-img
                alt="Logo Reper - Promotion de la santé"
                src="/assets/logos/reper.svg"
                position="right"
                contain
                max-width="190"
              >
              </v-img>
            </a>
          </v-col>
        </v-row>
      </v-container>
    </header>
    <v-main
      class="backgroundImg"
      style="padding-top: 30px; min-height: calc(100vh - 165px)"
      v-bind:style="background"
    >
      <v-container
        class="d-flex align-center justify-center"
        style="height: 100%"
      >
        <div
          class="ma-12 px-7 py-0 my-0 d-flex flex-column justify-center align-center white--text"
        >
          <h1 class="white--text">
            {{ $t('final-result') }}:
            <span style="font-size: 50px"
              >{{ result }}/{{ questions.length }}</span
            >
          </h1>
          <p class="text-center mt-4 mb-10 white--text" style="font-size: 20px">
            {{ $t('bravo') }}
          </p>
          <v-row>
            <v-col cols="12" md="6" class="d-flex justify-center">
              <v-btn
                class="mt-2 pt-4 pb-4 text-capitalize next-btn white--text"
                :class="'btn-' + quiz.class"
                :to="'/' + quizkey"
                dark
                x-large
              >
                {{ $t('redo') }}
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" class="d-flex justify-center">
              <v-btn
                class="mt-2 pt-4 pb-4 text-capitalize next-btn white--text"
                color="primary"
                :to="'/'"
                x-large
              >
                {{ $t('discover') }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row v-if="quiz.info_compl" class="mt-15">
            <v-col cols="12" class="text-center mt-15">
              <h3 class="white--text">{{ $t('info-compl') }}</h3>
            </v-col>
            <v-col cols="12" class="pt-0">
              <div
                v-html="quiz.info_compl"
                class="compl text-center pt-0 mt-0"
              ></div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-main>
    <footer>
      <v-container fluid class="d-md-flex justify-end">
        <p
          v-if="showpartners && quiz.partners.length > 0"
          class="my-4 mr-1"
          style="text-align: center; margin: 0"
        >
          {{ $t('partner') }}
        </p>
        <p
          v-if="showpartners"
          class="my-4 mx-0 mr-10"
          style="text-align: center; margin: 0"
        >
          <a
            v-for="partner in quiz.partners"
            :key="partner.uri"
            :href="partner.uri"
            style="text-decoration: none"
            target="_blank"
            >{{ partner.title }}</a
          >
        </p>
      </v-container>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Home',
  props: ['quizkey'],
  data() {
    return {
      showpartners: false,
      result: localStorage.result,
      quiz: JSON.parse(localStorage.quiz),
    }
  },
  created() {
    this.showpartners = this.quiz.partners.length
    this.questions = this.quiz.questions
    this.background =
      "background:url('/assets/backgrounds/background-" +
      this.quiz.class +
      ".jpg'); background-size:cover;background-position:center;"
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$i18n.locale === 'de') {
          document.title = 'Bischfit | by REPER'
        } else {
          document.title = 'Quizaddict | by REPER'
        }
      },
    },
  },
}
</script>

<style>
.backgroundImg {
  background-position: center;
  background-size: cover;
  width: 100vw;
}

a .compl {
  width: 50%;
}

.sport {
  color: #6d4371 !important;
}

.hasard a {
  color: #82b623 !important;
}
.ecrans a {
  color: #004c76 !important;
}
.multi a {
  color: #f2bb3f !important;
}
.cannabis a {
  color: #52821d !important;
}
.alcool a {
  color: #820f01 !important;
}
.general a {
  color: #f2bb3f !important;
}

.btn-sport,
.footer-sport {
  background: #6d4371 !important;
  color: white !important;
}

.btn-hasard,
.footer-hasard {
  background: #82b623 !important;
  color: #ffffff !important;
}
.btn-ecrans,
.footer-ecrans {
  background: #004c76 !important;
  color: #ffffff !important;
}
.btn-multi,
.footer-multi {
  background: #f2bb3f !important;
  color: #ffffff !important;
}

.btn-cannabis,
.footer-cannabis {
  background: #52821d !important;
  color: #ffffff !important;
}
.btn-alcool,
.footer-alcool {
  background: #820f01 !important;
  color: #ffffff !important;
}
.btn-general,
.footer-general {
  background: #f2bb3f !important;
  color: #ffffff !important;
}
.footer {
  min-width: 71px;
}

@media screen and (max-width: 800px) {
  .compl {
    width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .logo {
    width: 130px;
  }
}
@media screen and (max-width: 800px) {
  .logoreper {
    width: 100px;
  }
}
</style>
