<template>
  <div v-if="!loading" class="home">
    <header class="header">
      <v-container fluid>
        <v-row class="ml-10 mr-10">
          <v-col cols="12" sm="6">
            <router-link
              to="/"
              style="height: 100%; width: 100%"
              class="d-flex align-center justify-center justify-sm-start"
            >
              <v-img
                alt="Quiz Addict - Reper"
                :src="
                  '/assets/logos/' + $i18n.locale + '/' + quiz.class + '.png'
                "
                max-width="140"
              >
              </v-img>
            </router-link>
          </v-col>
          <v-col cols="12" sm="6">
            <a
              href="https://www.reper-fr.ch"
              target="_blank"
              style="height: 100%; width: 100%"
              class="d-flex align-center justify-center justify-sm-end"
            >
              <v-img
                alt="Logo Reper - Promotion de la santé"
                src="/assets/logos/reper.svg"
                position="right"
                contain
                max-width="190"
              >
              </v-img>
            </a>
          </v-col>
        </v-row>
      </v-container>
    </header>
    <v-main
      class="backgroundImg-home"
      style="padding-top: 50px; min-height: calc(100vh - 165px)"
      :style="background"
    >
      <v-container
        class="d-flex align-center justify-center"
        style="height: 100%"
      >
        <v-row class="justify-center text-center">
          <v-col cols="10">
            <h1 class="text-center white--text">
              {{ quiz.title }}
            </h1>
          </v-col>
          <v-col cols="8">
            <p class="intro text-center white--text">
              {{ quiz.highlight }}
            </p>
          </v-col>
          <v-col cols="12">
            <v-btn
              class="pt-5 pb-5 pl-6 pr-6 text-capitalize next-btn go-btn"
              :class="'btn-' + quiz.class"
              :to="'/' + quiz.key + '/question/0'"
              x-large
            >
              {{ $t('go-quiz') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <footer>
      <v-container fluid class="d-md-flex justify-end">
        <p
          v-if="showpartners && quiz.partners.length > 0"
          class="my-4 mr-1"
          style="text-align: center; margin: 0"
        >
          {{ $t('partner') }}
        </p>
        <p
          v-if="showpartners"
          class="my-4 mx-0 mr-10"
          style="text-align: center; margin: 0"
        >
          <a
            v-for="partner in quiz.partners"
            :key="partner.uri"
            :href="partner.uri"
            style="text-decoration: none"
            target="_blank"
            >{{ partner.title }}</a
          >
        </p>
      </v-container>
    </footer>
  </div>
</template>

<script>
import apiCall from '@/utils/api.js'
export default {
  name: 'Home',
  props: ['quizkey'],
  data: () => ({
    showpartners: false,
    quiz: {},
    loading: true,
  }),
  created() {
    localStorage.setItem('salon', '')
    localStorage.removeItem('questionsRep')
    apiCall({ url: '/' + this.quizkey, method: 'get' }).then((resp) => {
      localStorage.quiz = JSON.stringify(resp.data)
      this.quiz = resp.data

      this.showpartners = this.quiz.partners.length

      this.background =
        "background:url('/assets/backgrounds/background-" +
        this.quiz.class +
        ".jpg'); background-size:cover;background-position:center;"
      this.logo = this.quiz.logo
      this.partners = this.quiz.partners
      this.loading = false
      localStorage.result = 0
    })
  },
}
</script>

<style lang="scss">
.backgroundImg-home {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
}

.btn-sport,
.footer-sport {
  background: #6d4371 !important;
  color: white !important;
}

.btn-hasard,
.footer-hasard {
  background: #82b623 !important;
  color: #ffffff !important;
}
.btn-ecrans,
.footer-ecrans {
  background: #004c76 !important;
  color: #ffffff !important;
}
.btn-multi,
.footer-multi {
  background: #f2bb3f !important;
  color: #ffffff !important;
}

.btn-cannabis,
.footer-cannabis {
  background: #52821d !important;
  color: #ffffff !important;
}
.btn-alcool,
.footer-alcool {
  background: #820f01 !important;
  color: #ffffff !important;
}
.btn-general,
.footer-general {
  background: #f2bb3f !important;
  color: #ffffff !important;
}

@media screen and (max-width: 1000px) {
  .logo {
    width: 130px;
  }
}
@media screen and (max-width: 800px) {
  .logoreper {
    width: 100px;
  }
}

.v-btn:not(.v-btn--round).v-size--default {
  height: auto;
  color: white;
}

.go-btn {
  height: auto !important;
}

.next-btn {
  font-family: 'Fredoka One', cursive;
  font-size: 20px !important;
}
</style>
